export const constants = {
  regex: {
    password: /(?!.*[\s])(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
    phone:
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
  },
  contentfulTypes: {
    button: "button",
    whyGsf: "whyGsf",
    cmsPage: "cmsPage",
    aboutUs: "aboutUs",
    homePage: "homePage",
    megaMenu: "megaMenu",
    dealsPage: "dealsPage",
    plpBanner: "plpBanner",
    contactUs: "contactUs",
    signupPage: "signupPage",
    placeholder: "placeholder",
    blogCategory: "blogCategory",
    sitemapLinks: "sitemapLinks",
    categoryPage: "categoryPage",
    makesAndModels: "makesmodels",
    organisation: "organisationSchema",
    deliveryInfoPage: "deliveryInfoPage",
    knowledgeHubBlog: "knowledgeHubBlog",
    cmsPageSubsection: "cmsPageSubsection",
    knowledgeHubSection: "knowledgeHubSection",
    knowledgeHubListingPage: "knowledgeHubListingPage",
  },
  contentfulSlugs: {
    tools: "tools",
    klarna: "klarna",
    contact: "contact",
    aboutUs: "about-us",
    menuItem: "menuItem",
    accessories: "accessories",
    cookiePolicy: "cookie-policy",
    storeLocator: "store-locator",
    pdpTabs: "pdp-bottom-section",
    privacyPolicy: "privacy-policy",
    entryId: "1BoN619Em9mazP3w9pNE93",
    returnsAndRefunds: "guest-returns",
    clickAndCollect: "click-and-collect",
    termsAndConditions: "terms-and-conditions",
  },
  cookies: {
    locale: "NEXT_LOCALE",
    new_vehicle: "new_vehicle",
    selectedBranch: "branch-url",
    manual_lookup: "manual-lookup",
    specialCoupon: "specialCoupon",
    customerToken: "customer-token",
    customerTokenExpiryHours: 90 * 24,
    selectedVehicle: "customer-vehicle",
    headerBannerClosed: "header_banner_closed",
    smartBannerCouponApplied: "smartBannerCouponApplied",
    specialCouponInitalLoadCheck: "specialCouponInitalLoadCheck",
    searchProductClicked: "searchProductClicked",
  },
  localStorage: {
    cartId: "cartId",
    cncAlerts: "cncAlerts",
    selectedBranch: "selectedBranch",
    placeOrderToken: "placeOrderToken",
    recently_viewed: "recently_viewed",
    isBranchSelected: "isBranchSelected",
    isCncSelected : "isCncSelected",
    cartAddressId: "cartShippingAddressId",
    shouldFetchCartDetails: "shouldFetchCartDetails",
    klarnaCartDetails: "klarnaCartDetails",
  },
  currency: {
    USD: "$",
    GBP: "£",
    EUR: "€",
  },
  contentTypes: {
    bloglist: "BLOGS",
    product: "PRODUCT",
    blogs: "BLOGSINFO",
    sitemap: "SITEMAP",
    category: "CATEGORY",
    CMS_PAGE: "CMS_PAGE",
    branches: "BRANCHES",
    branch: "BRANCHINFO",
    makeselected: "MAKESELECTED",
    search_result: "SEARCHRESULT",
    makeandmodels: "MAKEANDMODELS",
    modelselected: "MODELSELECTED",
    winter_essentials: "winter-essentials",
  },
  perPageSizes: {
    PLP: 10,
    subCategoryListing: 12,
  },
  image_types: {
    brand: "brand",
    product: "product",
    category: "category",
    brand_recommended: "brand_recommended",
    product_recommended: "product_recommended",
    product_best_seller: "product_best_seller",
  },
  vehicle_fields: {
    model: "modelId",
    body: "bodyStyleId",
    make: "manufacturerId",
    year: "modelVersionId",
    engine: "engineSizeId",
    type: "type",
  },
  sortingOptions: {
    PLP: [
      {
        direction: "asc",
        label: "Position",
        field: "position",
        key: "position",
      },
      {
        direction: "desc",
        label: "Best Seller",
        field: "best_seller",
        key: "best_seller_desc",
      },
      {
        field: "price",
        direction: "asc",
        key: "price_low_to_high",
        label: "Price - Low to High",
      },
      {
        field: "price", 
        direction: "desc",
        key: "price_high_to_low",
        label: "Price - High to Low",
      },
      {
        field: "name",
        direction: "asc",
        key: "product_title_asc",
        label: "Product Title (A to Z)",
      },
      {
        field: "name",
        direction: "desc",
        key: "product_title_desc",
        label: "Product Title (Z to A)",
      },
    ],
  },
  pageSizes: [10, 20, 50],
  pageSizesSubcategory: [12, 24, 48],
  service_response: {
    not_found: "VehicleNotFound",
    vehicle_found: "Vehicle_Found",
    modal_not_found: "ModelNotFound",
    unavailable: "InternalServerError",
  },
  time_format: ["h,m,s", "H,m,s"],
  featured_tools: "featured_tools",
  featured_accessories: "featured_accessory",
  image_extensions: [".gif", ".jpg", ".jpeg", ".png", ".svg", ".webp", ".bmp"],
  file_extensions: [".pdf", ".xml", ".css", ".js", ".ico"],
  unindexed_urls: ["/admin", "/checkout", "/customer", "/results"],
  unindexed_params: [
    "SID",
    "reference_number",
    "___SID",
    "oil_viscosity_sae",
    "quality",
    "sort_by",
    "auto_apply_coupon",
  ],
  next_routes: [
    "static/webpack/",
    "static/chunks",
    "static/css",
    "static/media",
  ],
  excludedPaths: [
    "/checkout/shipping",
    "/checkout/clickcollect",
    "/checkout/cart",
    "/catalogsearch/result",
  ],
};
