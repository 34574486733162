import Cookies from "js-cookie";
import { updateBranch } from "./misc";
import { constants } from "@/lib/utils/constants";
import { setToStorage } from "@/lib/utils/helpers/plp";
import { getProductsStocks } from "@/lib/services/api";
import { formatSavedBranch } from "@/lib/utils/cartMethods";
import {
  mergeCarts,
  getCartItems,
  setCnCBranch,
  resetCnCBranch,
  resetCartAddress,
  createCartForGuestUser,
  getCartForLoggedInUser,
  getClickAndCollectStock,
  setBillingAddressToCart,
  setShippingAddressToCart,
  setShippingMethodOnCart,
} from "@/lib/services/cart";
import {
  getProductsMap,
  handleThunkResponse,
  handleGraphqlResponse,
} from "@/lib/utils/functions";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const initialState = {
  cartId: null,
  products: {},
  freeGifts: {},
  cartPrice: null,
  uniqCount: 0,
  totalCount: 0,
  appliedCoupons: null,
  shippingAddresses: null,
  billingAddress: null,
  branchId: null,
  isHomeDeliveryAvailable: true,
  isCnCAvailable: true,
  isBranchModalOpen: false,
  selectedPaymentMethod: "paynow",
  cncAlerts: {
    basket: {
      text: "Ready to Collect within",
      timeString: " 30 minutes",
    },
    checkout: "Available for collection within 30 Minutes",
    confirmation:
      "Your order will be available for collection within 30 Minutes. ",
  },
  email: null,
  cncHubData: [],
  isCncSelected: true,
  isPoci: false,
};

const { cncAlerts, ...cartWithoutAlerts } = initialState;

export const fetchCartForGuestUser = createAsyncThunk(
  "cart/fetchCartForGuestUser",
  async () => {
    try {
      const res = await createCartForGuestUser();
      const data = handleGraphqlResponse(res);
      //Transform the response
      const cartId = data.createGuestCart.cart.id;
      localStorage.setItem(constants.localStorage.cartId, cartId);
      return cartId;
    } catch (err) {
      return Promise.reject(err.message);
    }
  },
);

export const fetchCartItems = createAsyncThunk(
  "cart/fetchCartItems",
  async (payload, thunkApi) => {
    try {
      const res = await getCartItems({ getBranchInfo: true });
      const data = handleGraphqlResponse(res);
      const isPoci = data?.cart?.is_poci_collection_method;
      // const selectedPaymentMethod=isPoci?'paynow':'paystore'
      localStorage.setItem("isPoci", isPoci);
      const products = data?.cart?.itemsV2?.items;
      const productsMap = getProductsMap(products)?.cartProducts;
      const freeGiftMap = getProductsMap(products)?.freeGifts;
      const cartPrice = data?.cart?.prices || null;
      const appliedCoupons = data?.cart?.applied_coupons || null;
      const uniqCount = data?.cart?.itemsV2?.total_count;
      const totalCount = data?.cart?.total_quantity;
      const shippingAddresses = data?.cart?.shipping_addresses;
      const billingAddress = data?.cart?.billing_address;
      const branchId = data?.cart?.clickandcollect_branch_id;
      const isHomeDeliveryAvailable = data?.cart?.is_home_delivery_available;

      const email = data?.cart?.email;
      const branch = data?.cart?.branch;
      if (branch) {
        const formattedBranch = formatSavedBranch(branch);
        setToStorage(constants.localStorage.selectedBranch, formattedBranch);
        thunkApi.dispatch(updateBranch(formattedBranch));
      }

      return {
        products: productsMap,
        freeGifts: freeGiftMap,
        cartPrice,
        appliedCoupons,
        totalCount,
        uniqCount,
        shippingAddresses,
        billingAddress,
        branchId,
        isHomeDeliveryAvailable,
        email,
        isPoci,
        // selectedPaymentMethod
      };
    } catch (err) {
      if (err.message.includes("Could not find a cart with ID")) {
        try {
          const thunkRes = await thunkApi.dispatch(fetchCartForGuestUser());
          handleThunkResponse(thunkRes);
        } catch (cartIdError) {
          return Promise.reject(cartIdError.message);
        }
      } else {
        return Promise.reject(err.message);
      }
    }
  },
);

export const mergeCartsThunk = createAsyncThunk(
  "cart/mergeCarts",
  async ({ guestCartId, getBranchInfo }, thunkApi) => {
    try {
      const res = await mergeCarts({
        guestCartId,
        getBranchInfo,
      });
      const data = handleGraphqlResponse(res);
      const products = data?.mergeCarts?.itemsV2?.items;
      const productsMap = getProductsMap(products)?.cartProducts;
      const freeGiftMap = getProductsMap(products)?.freeGifts;
      const cartPrice = data?.mergeCarts?.prices || null;
      const appliedCoupons = data?.mergeCarts?.applied_coupons || null;
      const uniqCount = data?.mergeCarts?.itemsV2?.total_count;
      const totalCount = data?.mergeCarts?.total_quantity;
      const shippingAddresses = data?.mergeCarts?.shipping_addresses;
      const billingAddress = data?.mergeCarts?.billing_address;
      const branchId = data?.mergeCarts?.clickandcollect_branch_id;
      const isHomeDeliveryAvailable =
        data?.mergeCarts?.is_home_delivery_available;
      const cartId = data?.mergeCarts?.id;

      const email = data?.mergeCarts?.email;
      const branch = data?.mergeCarts?.branch;

      // get state for customer and set addresses on cart
      const customer = thunkApi.getState()?.customer?.details;

      localStorage.setItem(constants.localStorage.cartId, cartId);

      //set shipping address
      if (!shippingAddresses || !shippingAddresses.length) {
        if (customer.default_shipping != null && !branch) {
          thunkApi.dispatch(
            setShippingAddressThunk({
              addressId: customer?.default_shipping?.id,
            }),
          );
        }
      }

      //set billing address
      if (!billingAddress) {
        if (customer.default_billing != null) {
          thunkApi.dispatch(
            setBillingAddressThunk({
              addressId: customer?.default_billing?.id,
            }),
          );
        }
      }

      if (branch) {
        const formattedBranch = formatSavedBranch(branch);
        setToStorage(constants.localStorage.selectedBranch, formattedBranch);
        thunkApi.dispatch(updateBranch(formattedBranch));
        localStorage.setItem(constants.localStorage.isBranchSelected, true);
      }
      return {
        products: productsMap,
        freeGifts: freeGiftMap,
        cartPrice,
        appliedCoupons,
        totalCount,
        uniqCount,
        shippingAddresses,
        billingAddress,
        branchId,
        isHomeDeliveryAvailable,
        email,
        cartId,
      };
    } catch (err) {
      return Promise.reject(err.message);
    }
  },
);

export const fetchCartForLoggedInUser = createAsyncThunk(
  "cart/fetchCartForLoggedInUser",
  async (setAddressToCart = true, thunkApi) => {
    try {
      const res = await getCartForLoggedInUser();
      const data = handleGraphqlResponse(res);
      //Transform the response
      const cartId = data.customerCart.id;
      const cartEmail = data.customerCart.email;
      localStorage.setItem(constants.localStorage.cartId, cartId);

      // get state for customer and set addresses on cart
      const customer = thunkApi.getState()?.customer?.details;
      const cartDetails = thunkApi.getState()?.cart;

      if (setAddressToCart) {
        //set shipping address
        if (
          !cartDetails?.shippingAddresses ||
          !cartDetails?.shippingAddresses.length
        ) {
          if (customer.default_shipping != null) {
            thunkApi.dispatch(
              setShippingAddressThunk({
                addressId: customer?.default_shipping.id,
              }),
            );
          }
        }

        //set billing address
        if (!cartDetails.billingAddress != null) {
          if (customer?.default_billing) {
            thunkApi.dispatch(
              setBillingAddressThunk({
                addressId: customer?.default_billing.id,
              }),
            );
          }
        }
      }

      return { cartId, email: cartEmail };
    } catch (err) {
      return Promise.reject(err.message);
    }
  },
);

export const setShippingAddressThunk = createAsyncThunk(
  "cart/setShippingAddress",
  async ({ formData, addressId }, thunkApi) => {
    const customerToken = Cookies.get(constants.cookies.customerToken);
    const cartId = localStorage.getItem(constants.localStorage.cartId);
    const isCncSelected = localStorage.getItem(
      constants.localStorage.isCncSelected,
    );

    // create cart id when setting shipping address
    if (!cartId) {
      if (customerToken) {
        await thunkApi
          .dispatch(fetchCartForLoggedInUser(false))
          .then(handleThunkResponse);
      }
    }

    // reset cart branch id when setting shipping address

    if (
      localStorage.getItem(constants.localStorage.isBranchSelected) &&
      !isCncSelected
    ) {
      await thunkApi.dispatch(resetCartBranchThunk()).then(handleThunkResponse);
    }

    try {
      const res = await setShippingAddressToCart({
        formData,
        addressId,
      });
      const data = handleGraphqlResponse(res);
      const products = data?.setShippingAddressesOnCart?.cart?.itemsV2?.items;
      const productsMap = getProductsMap(products)?.cartProducts;
      const freeGiftMap = getProductsMap(products)?.freeGifts;
      const cartPrice = data?.setShippingAddressesOnCart?.cart?.prices || null;
      const shippingAddresses =
        data?.setShippingAddressesOnCart?.cart?.shipping_addresses;
      const uniqCount =
        data?.setShippingAddressesOnCart?.cart?.itemsV2?.total_count;
      const totalCount = data?.setShippingAddressesOnCart?.cart?.total_quantity;
      const branchId =
        data?.setShippingAddressesOnCart?.cart?.clickandcollect_branch_id;
      const isHomeDeliveryAvailable =
        data?.setShippingAddressesOnCart?.cart?.is_home_delivery_available;
      const cartShippingAddressId = addressId;
      if (cartShippingAddressId && !isCncSelected) {
        localStorage.setItem(constants.localStorage.cartAddressId, addressId);
      }
      return {
        products: productsMap,
        freeGifts: freeGiftMap,
        cartPrice,
        totalCount,
        uniqCount,
        shippingAddresses,
        cartShippingAddressId,
        branchId,
        isHomeDeliveryAvailable,
      };
    } catch (err) {
      return Promise.reject(err.message);
    }
  },
);

export const setBillingAddressThunk = createAsyncThunk(
  "cart/setBillingAddress",
  async ({ formData, addressId, same_as_shipping }, thunkApi) => {
    const customerToken = Cookies.get(constants.cookies.customerToken);
    const cartId = localStorage.getItem(constants.localStorage.cartId);

    // create cart id when setting shipping address
    if (!cartId) {
      if (customerToken) {
        await thunkApi
          .dispatch(fetchCartForLoggedInUser(false))
          .then(handleThunkResponse);
      }
    }

    try {
      const res = await setBillingAddressToCart({
        formData,
        addressId,
        same_as_shipping,
      });
      const data = handleGraphqlResponse(res);
      const billingAddress =
        data?.setBillingAddressOnCart?.cart?.billing_address;
      return {
        billingAddress,
      };
    } catch (err) {
      return Promise.reject(err.message);
    }
  },
);

export const setShippingMethodThunk = createAsyncThunk(
  "cart/setShippingMethod",
  async ({ carrier_code, method_code }) => {
    try {
      const res = await setShippingMethodOnCart({
        carrier_code,
        method_code,
      });
      const data = handleGraphqlResponse(res);
      const shippingAddresses =
        data?.setShippingMethodsOnCart?.cart?.shipping_addresses;
      const cartPrice = data?.setShippingMethodsOnCart?.cart?.prices || null;
      return {
        shippingAddresses,
        cartPrice,
      };
    } catch (err) {
      return Promise.reject(err.message);
    }
  },
);

export const resetCartAddressThunk = createAsyncThunk(
  "cart/resetAddress",
  async (type) => {
    try {
      const res = await resetCartAddress(type);
      const data = handleGraphqlResponse(res);
      const products = data?.resetAddress?.itemsV2?.items;
      const productsMap = getProductsMap(products)?.cartProducts;
      const freeGiftMap = getProductsMap(products)?.freeGifts;
      const cartPrice = data?.resetAddress?.prices || null;
      const shippingAddresses = data?.resetAddress?.shipping_addresses;
      const billingAddress = data?.resetAddress?.billing_address;
      const branchId = data?.resetAddress?.clickandcollect_branch_id;
      const isHomeDeliveryAvailable =
        data?.resetAddress?.is_home_delivery_available;
      return {
        products: productsMap,
        freeGifts: freeGiftMap,
        cartPrice,
        shippingAddresses,
        billingAddress,
        branchId,
        isHomeDeliveryAvailable,
      };
    } catch (err) {
      return Promise.reject(err.message);
    }
  },
);

export const setCnCBranchThunk = createAsyncThunk(
  "cart/setCnCBranch",
  async ({ branch, fromPOCI }, thunkApi) => {
    try {
      const customerToken = Cookies.get(constants.cookies.customerToken);
      const cartId = localStorage.getItem(constants.localStorage.cartId);

      // create cart id when a cnc branch is selected
      if (!cartId) {
        if (!customerToken) {
          await thunkApi
            .dispatch(fetchCartForGuestUser())
            .then(handleThunkResponse);
        } else {
          await thunkApi
            .dispatch(fetchCartForLoggedInUser())
            .then(handleThunkResponse);
        }
      }

      const setNewBranch = branch?.setNew ?? true;

      const setPreferredBranch = customerToken
        ? branch?.setPreffered ?? false
        : false;

      if (setNewBranch) {
        const { branch_id } = branch;
        const res = await setCnCBranch({
          branchId: branch_id,
          setPreferred: setPreferredBranch,
        });
        const data = handleGraphqlResponse(res);
        const isPoci = data?.setCollectBranch?.is_poci_collection_method;
        //const selectedPaymentMethod=isPoci?'paynow':'paystore'
        localStorage.setItem("isPoci", isPoci);
        const products = data?.setCollectBranch?.itemsV2?.items;
        const productsMap = getProductsMap(products)?.cartProducts;
        const freeGiftMap = getProductsMap(products)?.freeGifts;
        const cartPrice = data?.setCollectBranch?.prices || null;
        const shippingAddresses = data?.setCollectBranch?.shipping_addresses;
        const billingAddress = data?.setCollectBranch?.billing_address;
        const _branchId = data?.setCollectBranch?.clickandcollect_branch_id;

        if (fromPOCI) {
          if (
            billingAddress &&
            (!shippingAddresses || !shippingAddresses?.length)
          ) {
            const isGuest = !Cookies.get(constants.cookies.customerToken);
            if (isGuest) {
              let obj = {
                region: billingAddress?.region?.label
                  ? billingAddress?.region?.label
                  : "",
                country_code: "GB",
                save_in_address_book: true,
                firstname: billingAddress?.firstname,
                lastname: billingAddress?.lastname,
                telephone: billingAddress?.telephone,
                company: billingAddress?.company,
                street: [...(billingAddress?.street || [])],
                city: billingAddress?.city,
                postcode: billingAddress?.postcode,
              };
              thunkApi.dispatch(
                setShippingAddressThunk({
                  formData: obj,
                }),
              );
            } else {
              thunkApi.dispatch(
                setShippingAddressThunk({
                  addressId: billingAddress.customer_address_id,
                }),
              );
            }
          }
        }

        //format branch info to be saved
        const formattedBranch = formatSavedBranch(branch);
        setToStorage(constants.localStorage.selectedBranch, formattedBranch);
        setToStorage(constants.localStorage.cncAlerts, branch?.alerts || {});
        thunkApi.dispatch(updateBranch(formattedBranch));

        //set the alert text
        return {
          products: productsMap,
          freeGifts: freeGiftMap,
          cartPrice,
          shippingAddresses,
          billingAddress,
          cncAlerts: branch?.alerts,
          branchId: _branchId,
          isPoci: isPoci,
          //selectedPaymentMethod:selectedPaymentMethod
        };
      }

      //format branch info to be saved
      const formattedBranch = formatSavedBranch(branch);
      setToStorage(constants.localStorage.selectedBranch, formattedBranch);
      setToStorage(constants.localStorage.cncAlerts, branch?.alerts || {});
      thunkApi.dispatch(updateBranch(formattedBranch));
      localStorage.setItem(constants.localStorage.isBranchSelected, true);

      //set the alert text
      return {
        cncAlerts: branch?.alerts,
      };
    } catch (err) {
      return Promise.reject(err.message);
    }
  },
);

export const resetCartBranchThunk = createAsyncThunk(
  "cart/resetCnCBranch",
  async (payload, thunkApi) => {
    try {
      const res = await resetCnCBranch();
      const data = handleGraphqlResponse(res);
      const cncBranchId = data?.resetCollectBranch?.clickandcollect_branch_id;
      // clear selected shipping methods after branch reset
      let shippingAddresses = data?.resetCollectBranch?.shipping_addresses;
      if (shippingAddresses.length) {
        shippingAddresses = [
          {
            ...data?.resetCollectBranch?.shipping_addresses?.[0],
            selected_shipping_method: null,
          },
        ];
      }
      localStorage.removeItem(constants.localStorage.isBranchSelected);
      return {
        branchId: cncBranchId,
        shippingAddresses,
      };
    } catch (err) {
      return Promise.reject(err.message);
    }
  },
);
export const resetCnCBranchThunk = createAsyncThunk(
  "cart/resetCnCBranch",
  async (payload, thunkApi) => {
    try {
      const res = await resetCnCBranch();
      const data = handleGraphqlResponse(res);
      const products = data?.resetCollectBranch?.itemsV2?.items;
      const shippingAddresses = data?.resetCollectBranch?.shipping_addresses;
      const productsMap = getProductsMap(products)?.cartProducts;
      const freeGiftMap = getProductsMap(products)?.freeGifts;
      const cartPrice = data?.resetCollectBranch?.prices || null;
      const cncBranchId = data?.resetCollectBranch?.clickandcollect_branch_id;
      localStorage.removeItem(constants.localStorage.selectedBranch);
      localStorage.removeItem(constants.localStorage.cncAlerts);
      thunkApi.dispatch(updateBranch(null));
      return {
        products: productsMap,
        freeGifts: freeGiftMap,
        shippingAddresses,
        cartPrice,
        branchId: cncBranchId,
      };
    } catch (err) {
      return Promise.reject(err.message);
    }
  },
);

export const fetchClickAndCollectStock = createAsyncThunk(
  "cart/getClickAndCollectStock",
  async (branch, thunkApi) => {
    try {
      const { branch_id } = branch;
      const res = await getClickAndCollectStock({ branchId: branch_id });
      const data = handleGraphqlResponse(res);
      const isCnCAvailable = data?.clickAndCollectStock?.is_cnc_available;
      const productStock = data?.clickAndCollectStock?.product_stock;
      thunkApi.dispatch(updateIsCnCAvailable(isCnCAvailable));
      return { productStock };
    } catch (err) {
      return Promise.reject(err.message);
    }
  },
);

export const fetchClickAndCollectHubStock = createAsyncThunk(
  "cart/fetchClickAndCollectHubStock",
  async (params, thunkApi) => {
    try {
      const res = await getProductsStocks(params);
      return res ?? {};
    } catch (err) {
      return Promise.reject(err.message);
    }
  },
);

const name = "cart";
const cart = createSlice({
  name,
  initialState,
  reducers: {
    setCartId(state, action) {
      const { payload: cartId } = action;
      state.cartId = cartId;
    },
    addOrUpdateProducts(state, action) {
      const { payload: productsMap } = action;
      const products = { ...productsMap };
      state.products = productsMap;
      Object.keys(products).forEach((uid) => {
        if (products[uid]?.cnc) {
          state.products[uid] = {
            ...state.products[uid],
            cnc: products[uid]?.cnc,
          };
        }
      });
    },
    addOrUpdateFreeGifts(state, action) {
      const { payload: productsMap } = action;
      const products = { ...state.freeGifts };
      Object.assign(state.freeGifts, productsMap);
    },
    addOrUpdateCartPrice(state, action) {
      const { payload: cartPrice } = action;
      state.cartPrice = cartPrice;
    },
    updateUniqCount(state, action) {
      const { payload: newUniqCount } = action;
      state.uniqCount = newUniqCount;
    },
    updateTotalCount(state, action) {
      const { payload: newTotalCount } = action;
      state.totalCount = newTotalCount;
    },
    removeCartProduct(state, action) {
      const { payload: productsMap } = action;
      state.products = productsMap;
    },
    removeCartProducts(state, action) {
      const { payload: productsMap } = action;
      state.products = productsMap;
    },
    addOrUpdateAppliedCoupons(state, action) {
      const { payload: appliedCoupons } = action;
      state.appliedCoupons = appliedCoupons;
    },
    updateIsHomedeliveryAvailable(state, action) {
      const { payload: isHomeDeliveryAvailable } = action;
      state.isHomeDeliveryAvailable = isHomeDeliveryAvailable;
    },
    updateIsCnCAvailable(state, action) {
      const { payload: isCnCAvailable } = action;
      state.isCnCAvailable = isCnCAvailable;
    },
    updateInitialAlerts(state, action) {
      const { payload } = action;
      state.cncAlerts = payload;
    },
    resetCncAlerts(state) {
      state.cncAlerts = null;
    },
    updateCartEmail(state, action) {
      const { payload: email } = action;
      state.email = email;
    },
    resetCart(state) {
      return {
        ...cartWithoutAlerts,
        cncAlerts: state.cncAlerts,
      };
    },
    toggleModalState(state, action) {
      const { payload: isOpen } = action;
      state.isBranchModalOpen = isOpen;
    },
    resetCompleteCart(state) {
      Object.assign(state, initialState);
    },
    setIsCncSelected(state, action) {
      const { payload } = action;
      state.isCncSelected = payload;
    },
    setSelectedPaymentMethod(state, action) {
      const { payload } = action;
      state.selectedPaymentMethod = payload;
    },
    updateShippingAddress(state, action) {
      const { payload } = action;
      state.shippingAddresses = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCartForGuestUser.fulfilled, (state, action) => {
      const { payload: cartId } = action;
      state.cartId = cartId;
    });
    builder.addCase(fetchCartForLoggedInUser.fulfilled, (state, action) => {
      const { email, cartId } = action?.payload;
      state.cartId = cartId;
      state.email = email;
    });
    builder.addCase(fetchCartItems.fulfilled, (state, action) => {
      const { payload } = action;
      const products = { ...state.products };
      Object.assign(state, payload);
      Object.keys(products).forEach((uid) => {
        if (products[uid]?.cnc) {
          state.products[uid] = {
            ...state.products[uid],
            cnc: products[uid]?.cnc,
          };
        }
      });
    });
    builder.addCase(mergeCartsThunk.fulfilled, (state, action) => {
      const { payload } = action;
      Object.assign(state, payload);
    });
    builder.addCase(setShippingAddressThunk.fulfilled, (state, action) => {
      const { payload } = action;
      Object.assign(state, payload);
    });
    builder.addCase(setShippingMethodThunk.fulfilled, (state, action) => {
      const { payload } = action;
      Object.assign(state, payload);
    });
    builder.addCase(setBillingAddressThunk.fulfilled, (state, action) => {
      const { payload } = action;
      Object.assign(state, payload);
    });
    builder.addCase(resetCartAddressThunk.fulfilled, (state, action) => {
      const { payload } = action;
      Object.assign(state, payload);
    });
    builder.addCase(setCnCBranchThunk.fulfilled, (state, action) => {
      const { payload } = action;
      const products = { ...state.products };
      Object.assign(state, payload);
      Object.keys(products).forEach((uid) => {
        if (products[uid]?.cnc) {
          state.products[uid] = {
            ...state.products[uid],
            cnc: products[uid]?.cnc,
          };
        }
      });
    });
    builder.addCase(resetCnCBranchThunk.fulfilled, (state, action) => {
      const { payload } = action;
      Object.assign(state, payload);
    });
    builder.addCase(fetchClickAndCollectStock.fulfilled, (state, action) => {
      const {
        payload: { productStock },
      } = action;
      productStock?.forEach((stock) => {
        const product = state.products[stock?.uid];
        if (product) {
          if (!product["cnc"]) {
            product["cnc"] = {};
          }

          product["cnc"] = { ...stock };
          delete product["cnc"].sku;
        }
      });
    });

    builder.addCase(fetchClickAndCollectHubStock.fulfilled, (state, action) => {
      const { payload } = action;
      state.cncHubData = payload;
    });
  },
});

export const {
  setCartId,
  addOrUpdateProducts,
  addOrUpdateFreeGifts,
  addOrUpdateCartPrice,
  resetCart,
  resetCompleteCart,
  resetCncAlerts,
  updateInitialAlerts,
  updateUniqCount,
  updateTotalCount,
  removeCartProduct,
  removeCartProducts,
  addOrUpdateAppliedCoupons,
  updateIsHomedeliveryAvailable,
  updateCartEmail,
  updateIsCnCAvailable,
  toggleModalState,
  setIsCncSelected,
  setSelectedPaymentMethod,
  updateShippingAddress,
} = cart.actions;

export const getLoader = (state) => state.uiState.loading;
export const getCartId = (state) => state.cart.cartId;
export const getCartShippingAddress = (state) =>
  state.cart.cartShippingAddressId;
export const getCartProducts = (state) => state.cart.products;
export const getFreeProducts = (state) => state.cart.freeGifts;
export const getCartAlerts = (state) => state.cart.cncAlerts;
export const getCartPrice = (state) => state.cart?.cartPrice;
export const getCartCounts = createSelector(
  [(state) => state.cart.uniqCount, (state) => state.cart.totalCount],
  (uniqCount, totalCount) => ({
    uniqCount,
    totalCount,
  }),
);
export const getAppliedCoupons = (state) => state.cart.appliedCoupons;
export const getShippingAddress = (state) => state.cart.shippingAddresses?.[0];
export const getBillingAddress = (state) => state.cart.billingAddress;
export const getBranchId = (state) => state.cart.branchId;
export const getIsHomeDeliveryAvailable = (state) =>
  state.cart.isHomeDeliveryAvailable;
export const getIsCnCAvailable = (state) => state.cart.isCnCAvailable;
export const getCartEmail = (state) => state.cart.email;
export const getShippingMethods = (state) =>
  state.cart.shippingAddresses?.[0]?.available_shipping_methods;
export const getSelectedShippingMethod = (state) =>
  state.cart.shippingAddresses?.[0]?.selected_shipping_method;
export const getCncHubData = (state) => state.cart.cncHubData;
export const getModalState = (state) => state.cart.isBranchModalOpen;
export const getIsCncSelected = (state) => state.cart.isCncSelected;

export default cart;
