import axios from "axios";
import { keys } from "../utils/keys";
import { apiBaseUrl } from "./backend";

/**
 * @param {{ email: string }} payload
 */
export const subscribeNewsletter = async ({ email }) => {
  const axiosRes = await axios.post(keys.general.backendGraphqlUrl, {
    variables: { email },
    query: `mutation SubscribeEmailToNewsletter($email: String!) {
      subscribeEmailToNewsletter(email: $email) {
        status
      }
    }`,
  });
  return axiosRes.data;
};

export const getContactFormOptions = async () => {
  const axiosRes = await axios.post(keys.general.backendGraphqlUrl, {
    query: `query { reasons { list } }`,
  });
  return axiosRes.data;
};

export const sendContactUsForm = async (payload) => {
  const axiosRes = await axios.post(
    keys.general.backendGraphqlUrl,
    {
      query: `mutation {
        contactUs(
          input: {
            comment: """${payload.comment}""",
            email: "${payload.email}",
            telephone: "${payload.telephone}",
            name: "${payload.firstName} ${payload.lastName}",
            reason: "${payload.reason}",
            vehicle_registration: "${payload.vehicle_registration}",
            order_number: "${payload.order_number}" 
          }
        ) {
          status
        }
		  }`,
    },
    {
      headers: {
        "X-ReCaptcha": payload.recaptchaToken,
      },
    },
  );
  return axiosRes.data;
};

export const getGlobalAssets = async () => {
  const axiosRes = await axios.get(keys.general.backendGraphqlUrl, {
    params: {
      query: `query {
        storeConfig {
          timezone contact_mobile_number seasonal_product_title seasonal_product_desc featured_product_title featured_product_desc popular_category_title popular_category_desc recentlyview_product_title recentlyview_product_desc
        } bestseller recommended currency { base_currency_symbol }
      }`,
    },
    paramsSerializer: {
      indexes: null,
    },
  });
  return axiosRes.data;
};

export const getAssetImages = async () => {
  const axiosRes = await axios.get(apiBaseUrl(), {
    params: {
      query: "query { bestseller recommended }",
    },
    paramsSerializer: {
      indexes: null,
    },
  });
  return axiosRes.data;
};

/**
 * For Details on fetching from reviews api.
 *  @see https://developer.reviews.io/reference/899f58c2c4431ec1000ec59a38b2916b
 * */
const fetchReviews = async (params, isRating = false) => {
  const query = new URLSearchParams({
    ...params,
    store: "gsf-car-parts",
  }).toString();
  return await axios.get(
    `${keys.general.reviewAPIUrl}${
      isRating ? "product/rating-batch" : "reviews"
    }?${query}`,
  );
};

export const getStoreReviews = async () => {
  const axiosRes = await fetchReviews({
    page: 1,
    minRating: 5,
    per_page: 10,
    sort: "date_desc",
    type: "store_review",
    include_unpublished_photos: true,
  });
  return axiosRes?.data || [];
};

export const getProductReviews = async (sku, page = 1, perPage = 3) => {
  try {
    const axiosRes = await fetchReviews({
      sku: sku,
      page: page,
      sort: "date_desc",
      per_page: perPage,
      type: "product_review",
    });
    return axiosRes;
  } catch (error) {
    return { data: null, error: error };
  }
};

export const getProductRating = async (skuList) => {
  try {
    const axiosRes = await fetchReviews({ sku: skuList.join(";") }, true);
    return axiosRes.data;
  } catch (error) {
    return { reviews: null, error: error };
  }
};
