import { constants } from "./constants";

export function hasOnlyWhitespace(text) {
  // Helper function to check for whitespace
  // Return true for completely empty field
  if (!text) return true;
  return text.trim().length === 0;
}

const keepLastTwoDigitsAfterDecimal = (num) => {
  let number = Number(num);
  if (isNaN(number)) return "0.00";
  const roundedNumber = Math.round(num * 100) / 100;
  const strNumber = roundedNumber.toString();
  if (!strNumber.includes(".")) {
    return strNumber.concat(".00");
  } else {
    const arr = strNumber?.split(".") || [];
    return arr?.[1]?.length === 1 ? strNumber?.concat("0") : strNumber;
  }
};

export const getPrice = (value, currency = "GBP", options = {}) => {
  const { space = false } = options;
  const price = keepLastTwoDigitsAfterDecimal(value);
  return `${constants.currency[currency]}${space ? " " : ""}${price}`;
};

export const formatSavedBranch = (branchDetails) => {
  const {
    branch_id,
    name,
    type,
    area,
    is_hub,
    is_NDC,
    closing_mon_fri,
    closing_sat,
    closing_sun,
    open_saturday,
    open_sunday,
    opening_mon_fri,
    opening_sat,
    opening_sun,
    distance,
    address1,
    address2,
    address3,
    address4,
    postcode,
    latitude,
    telephone,
    longitude,
    hyperlink: link,
    email,
    holiday_info,
  } = branchDetails;

  return {
    branch_id,
    title: `${type} ${name}`,
    name,
    type,
    area,
    is_hub: is_hub || "",
    is_NDC: is_NDC ||false,
    distance,
    address1,
    address2,
    address3,
    address4,
    postcode,
    latitude,
    telephone,
    longitude,
    link,
    email,
    closing_mon_fri,
    closing_sat,
    closing_sun,
    open_saturday,
    open_sunday,
    opening_mon_fri,
    opening_sat,
    opening_sun,
    holiday_info,
  };
};
