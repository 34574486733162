export const cartFragments = {
  cartPrice: `prices {
    gift_promo_discount
    specialcoupon_discount_amount
    grand_total { ...Money }
    subtotal_including_tax { ...Money }
    subtotal_excluding_tax { ...Money }
    discounts { label amount { ...Money }}
    applied_taxes { label amount { ...Money } }
    subtotal_with_discount_excluding_tax { ...Money }
  }`,
  cartItemFields: `fragment CartItemFields on CartItemInterface {
    uid is_available isFreegift discountPercent quantity
    prices {
      price { ...Money }
      price_including_tax { ...Money }
      row_total { ...Money }
      row_total_including_tax { ...Money }
      discounts {
        label applied_to amount { ...Money }
      }
      total_item_discount { ...Money }
    }
    product {
      id sku name url_key sold_in_pairs special_price special_to_date special_from_date specialCoupon_applied
      thumbnail { url label }
      extraVariable (breadcrumb_required: true) {
        qty
        breadcrumbs {
          id label link component_id
        }
        fitmentInfo { label value }
        brand_info {
          id title image brand_tier
        }
        breadcrumbs { label }
      }
      price_range {
        minimum_price {
          regular_price { value currency }
          final_price { value currency }
          discount { amount_off percent_off }
        }
      }
    }
  }`,
  addressFields: `fragment AddressFields on CartAddressInterface {
    customer_address_id firstname lastname company street city postcode telephone
    region { code label }
    country { code label }
  }`,
  shippingAddress: `fragment ShippingAddress on ShippingCartAddress {
    available_shipping_methods {
      amount { ...Money }
      available carrier_code carrier_title error_message method_code method_title
      price_excl_tax { ...Money }
      price_incl_tax { ...Money }
    }
    selected_shipping_method {
      carrier_code carrier_title method_code method_title
      amount { ...Money }
      price_excl_tax { ...Money }
      price_incl_tax { ...Money }
    }
  }`,
  shippingAddressWithoutMethod: `fragment ShippingAddress on ShippingCartAddress {
    available_shipping_methods {
      available carrier_code carrier_title error_message method_code method_title
      amount { ...Money }
      price_excl_tax { ...Money }
      price_incl_tax { ...Money }
    }
  }`,
  ProductInterface: `fragment Products on ProductInterface {
    uid name sku type_id url_key best_seller stock_status sold_in_pairs special_price show_on_basket is_recommended special_to_date special_from_date specialCoupon_applied
    promo_offer { label promo_image }
    short_description { html }
    thumbnail { url label }
    extraVariable (breadcrumb_required: true) {
      qty art_nr
      brand_info { id title image brand_tier }
      breadcrumbs { id link label component_id }
    }
    price_range {
      minimum_price {
        regular_price { value currency }
        final_price { value currency }
        discount { amount_off percent_off }
      }
      maximum_price {
        regular_price { value currency }
        final_price { value currency }
        discount { amount_off percent_off }
      }
    }
  }`,
};

export const aggregation = `fragment AggregationFragment on Aggregation {
  label count attribute_code options { count label value }
}`;

export const category = `fragment Category on CategoryInterface {
  id name description component_id
  breadcrumbs {
    category_id category_name category_level category_url_path category_url_key
  }
}`;

export const productListModel = (model = "listing") => {
  let fields = ` id sku name promo_offer { label promo_image } url_key best_seller special_price sold_in_pairs is_recommended specialCoupon_applied thumbnail { url label } price_range { minimum_price { regular_price { value currency } final_price { value currency }  discount { amount_off percent_off } } }`;
  switch (model) {
    case "listing":
      fields = `${fields} uid special_to_date special_from_date extraVariable (breadcrumb_required: false) { qty art_nr stop_start_compatible warranty_guaranteed_text brand_info { id title image brand_tier } fitmentInfo { label value } }`;
      break;
    case "home":
    case "recent":
      fields = `${fields} extraVariable (breadcrumb_required: false) { qty art_nr brand_info { id title } }`;
      if (model === "recent") {
        fields += " type_id";
      }
      break;
  }

  return `fragment Products on ProductInterface { ${fields} }`;
};

export const productDetailModel = () => {
  return `fragment Product on ProductInterface {
    id uid sku name url_key best_seller is_recommended sold_in_pairs special_price special_from_date special_to_date specialCoupon_applied
    description { html }
    promo_offer {
      label promo_image
      promo_products { name image sku }
    }
    extraVariable (breadcrumb_required: true) {
      qty art_nr stop_start_compatible warranty_guaranteed_text
      breadcrumbs { label }
      fitmentInfo { label value}
      brand_info { id title image brand_tier }
    }
    small_image {
      url label
    }
    media_gallery {
      url label position
    }
    price_range {
      minimum_price {
        regular_price {
          value currency
        }
        final_price {
          value currency
        }
        discount {
          amount_off percent_off
        }
      }
    }
  }`;
};
