import { getPageType } from "@/lib/utils/functions";
import { updateStoreWindowSet } from "../store/reducers/misc";
import { constants } from "@/lib/utils/constants";
import Cookies from "js-cookie";

export const setContext = (dispatch, retries = 5, interval = 500) => {
  if (!window.magentoStorefrontEvents) {
    if (retries <= 0) {
      console.warn("Magento Storefront Events not found after multiple attempts. Events will not be tracked.");
      return;
    }
    setTimeout(() => {
      setContext( dispatch, retries - 1, interval);
    }, interval);
    return;
  }
  try {

    window.magentoStorefrontEvents.context.setStorefrontInstance({
      environmentId: process.env.NEXT_PUBLIC_ENVIRONMENT_ID,
      environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
      storeUrl: process.env.NEXT_PUBLIC_STORE_URL,
      websiteId: process.env.NEXT_PUBLIC_WEBSITE_ID,
      websiteCode: process.env.NEXT_PUBLIC_WEBSITE_CODE,
      storeId:process.env.NEXT_PUBLIC_STORE_ID,
      storeCode: process.env.NEXT_PUBLIC_STORE_CODE,
      storeViewId: process.env.NEXT_PUBLIC_STORE_VIEW_ID,
      storeViewCode: process.env.NEXT_PUBLIC_STORE_VIEW_CODE,
      websiteName: process.env.NEXT_PUBLIC_WEBSITE_NAME,
      storeName: process.env.NEXT_PUBLIC_STORE_NAME,
      storeViewName: process.env.NEXT_PUBLIC_STORE_VIEW_NAME,
      baseCurrencyCode: process.env.NEXT_PUBLIC_BASE_CURRENCY_CODE,
      storeViewCurrencyCode: process.env.NEXT_PUBLIC_STORE_VIEW_CURRENCY_CODE,
      storefrontTemplate: process.env.NEXT_PUBLIC_STORE_FONT_TEMPLATE,
    });
    window.magentoStorefrontEvents.context.setSearchExtension({
      version: process.env.NEXT_PUBLIC_SEARCH_EXTENSION
    });
    window.magentoStorefrontEvents.context.setMagentoExtension({
      magentoExtensionVersion: process.env.NEXT_PUBLIC_MAGENTO_EXTENSION_VERSION
    });
    window.magentoStorefrontEvents.context.setDataServicesExtension({
      version: process.env.NEXT_PUBLIC_DATA_SERVICES_EXTENSION
    });
    window.magentoStorefrontEvents.context.setChannel({
      _id: process.env.NEXT_PUBLIC_CHANNEL_ID,
      _type: process.env.NEXT_PUBLIC_CHANNEL_TYPE
    });
    dispatch(updateStoreWindowSet(true));
  } catch (error) {
    console.error("Error initializing MSE:", error);
  }
};

export const setPageContext = () => {
  window.magentoStorefrontEvents.context.setPage({ pageType: getPageType() }
  );
};

export const firePlaceOrderEvent = (payload) => {
  if (!window.magentoStorefrontEvents) return;
  setPageContext();
  window.magentoStorefrontEvents.context.setShoppingCart(payload.shoppingCartCtx);
  window.magentoStorefrontEvents.context.setOrder(payload.orderCtx);
  window.magentoStorefrontEvents.context.setShopper({
    shopperId: Cookies.get(constants.cookies.customerToken) ? "logged-in" : "guest"
  });
  window.magentoStorefrontEvents.publish.placeOrder();
};


export const fireProductViewEvent = async (payload) => {
  if (!window.magentoStorefrontEvents) return;
  setPageContext();
  window.magentoStorefrontEvents.context.setProduct(payload);
  window.magentoStorefrontEvents.context.setShopper({
    shopperId: Cookies.get(constants.cookies.customerToken) ? "logged-in" : "guest"
  });
  window.magentoStorefrontEvents.publish.productPageView();
};

export const fireAddToCartEvent = async (payload) => {
  if (!window.magentoStorefrontEvents) return;
  setPageContext();
  window.magentoStorefrontEvents.context.setProduct(payload.productCtx);
  window.magentoStorefrontEvents.context.setShoppingCart(payload.shoppingCartCtx);
  window.magentoStorefrontEvents.context.setChangedProducts(payload.changedProductsCtx);
  window.magentoStorefrontEvents.context.setShopper({
    shopperId: Cookies.get(constants.cookies.customerToken) ? "logged-in" : "guest"
  });
  window.magentoStorefrontEvents.publish.addToCart();
};

export const fireSearchRequestSentEvent = async (payload) => {
  if (payload?.units?.[0]?.searchRequestId && !window.magentoStorefrontEvents) return;
  window.magentoStorefrontEvents.context.setSearchResults(null);
  setPageContext();
  window.magentoStorefrontEvents.context.setSearchInput(payload);
  window.magentoStorefrontEvents.context.setShopper({
    shopperId: Cookies.get(constants.cookies.customerToken) ? "logged-in" : "guest"
  });
  window.magentoStorefrontEvents.publish.searchRequestSent("desktop-search");
};

export const fireSearchResponseReceivedEvent = async (payload) => {
  if (payload?.mockSearchResults?.units?.[0]?.searchRequestId && !window.magentoStorefrontEvents) return;
  setPageContext();
  window.magentoStorefrontEvents.context.setSearchInput(payload.inputSearchContext)
  window.magentoStorefrontEvents.context.setSearchResults(payload.mockSearchResults);
  window.magentoStorefrontEvents.context.setShopper({
    shopperId: Cookies.get(constants.cookies.customerToken) ? "logged-in" : "guest"
  });
  window.magentoStorefrontEvents.publish.searchResponseReceived("desktop-search");
};

export const fireSearchProductClickEvent = async (payload, sku, inputSearchContext) => {
  if (payload?.units?.[0]?.searchRequestId && !window.magentoStorefrontEvents) return;
  setPageContext();
  window.magentoStorefrontEvents.context.setSearchInput(inputSearchContext)
  window.magentoStorefrontEvents.context.setSearchResults(payload);
  window.magentoStorefrontEvents.context.setShopper({
    shopperId: Cookies.get(constants.cookies.customerToken) ? "logged-in" : "guest"
  });
  window.magentoStorefrontEvents.publish.searchProductClick("desktop-search", sku);
};

export const fireSearchCategoryClickEvent = async (payload, categoryId, inputSearchContext) => {
  if (payload?.units?.[0]?.searchRequestId && !window.magentoStorefrontEvents) return;
  setPageContext();
  window.magentoStorefrontEvents.context.setSearchInput(inputSearchContext)
  window.magentoStorefrontEvents.context.setSearchResults(payload);
  window.magentoStorefrontEvents.context.setShopper({
    shopperId: Cookies.get(constants.cookies.customerToken) ? "logged-in" : "guest"
  });
  window.magentoStorefrontEvents.publish.searchCategoryClick("desktop-search", { categoryId: categoryId });
};

export const fireSearchResultViewEvent = async (payload) => {
  if (payload?.mockSearchResults?.units?.[0]?.searchRequestId && !window.magentoStorefrontEvents) return;
  setPageContext();
  window.magentoStorefrontEvents.context.setSearchInput(payload.inputSearchContext)
  window.magentoStorefrontEvents.context.setSearchResults(payload.mockSearchResults);
  window.magentoStorefrontEvents.context.setShopper({
    shopperId: Cookies.get(constants.cookies.customerToken) ? "logged-in" : "guest"
  });
  window.magentoStorefrontEvents.publish.searchResultsView("desktop-search");
};

export const firePageViewEvent = async () => {
  if (!window.magentoStorefrontEvents) return;
  setPageContext();
  window.magentoStorefrontEvents.context.setShopper({
    shopperId: Cookies.get(constants.cookies.customerToken) ? "logged-in" : "guest"
  });
  window.magentoStorefrontEvents.publish.pageView();
};

export const fireInitiateCheckoutEvent = async (payload) => {
  if (!window.magentoStorefrontEvents) return;
  setPageContext();
  window.magentoStorefrontEvents.context.setShoppingCart(payload);
  window.magentoStorefrontEvents.context.setShopper({
    shopperId: Cookies.get(constants.cookies.customerToken) ? "logged-in" : "guest"
  });
  window.magentoStorefrontEvents.publish.initiateCheckout();
};

export const fireShoppingCartViewEvent = async (payload) => {
  if (!window.magentoStorefrontEvents) return;
  setPageContext();
  window.magentoStorefrontEvents.context.setShoppingCart(payload);
  window.magentoStorefrontEvents.context.setShopper({
    shopperId: Cookies.get(constants.cookies.customerToken) ? "logged-in" : "guest"
  });
  window.magentoStorefrontEvents.publish.shoppingCartView();
};

export const fireOpenCartViewEvent = async (payload) => {
  if (!window.magentoStorefrontEvents) return;
  setPageContext();
  window.magentoStorefrontEvents.context.setShoppingCart(payload);
  window.magentoStorefrontEvents.context.setShopper({
    shopperId: Cookies.get(constants.cookies.customerToken) ? "logged-in" : "guest"
  });
  window.magentoStorefrontEvents.publish.openCart();
};